<template>
  <div class="change-pwd-btn" v-bind="$attrs" @click="handleClickUpdate">修改密码</div>
  <el-dialog
      v-model="dialogVisible"
      title="修改密码"
      width="520"
      modal-class="shop-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openChangePwd"
  >
    <el-form
        :rules="rules"
        ref="formRef"
        :model="fromData"
        class="chang-pwd-form large-input"
        label-position="top"
    >
      <el-form-item
          prop="oldPwd"
      >
        <el-input
        v-elInput
            v-model="fromData.oldPwd"
            type="text"
            class="no-number large-input"
            placeholder="请输入原密码"
            maxlength="30"
            input-style="font-size: 14px;"
            onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
        />
      </el-form-item>
      <el-form-item
          prop="newPwd"
      >
        <el-input
        v-elInput
            v-model="fromData.newPwd"
            type="password"
            class="no-number large-input"
            placeholder="请输入新密码"
            maxlength="30"
            input-style="font-size: 14px;"
            onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
        />
      </el-form-item>
      <el-form-item
          prop="newPwdCheck"
      >
        <el-input
        v-elInput
            v-model="fromData.newPwdCheck"
            type="password"
            class="no-number large-input"
            placeholder="确认新密码"
            maxlength="30"
            input-style="font-size: 14px;"
            onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
        />
      </el-form-item>
      <el-form-item
      >
        <div class="btn-box">
          <button type="button" class="wy-button info mr_16" @click="dialogVisible = false">取消</button>
          <button type="button" class="wy-button primary" @click="submitForm(formRef)">确认</button>
        </div>
      </el-form-item>
    </el-form>

  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, reactive, ref} from "vue";
import {updatePwd} from "@utils/api/person/index.js"
import {formRules} from '../../../config'
import {setBaseUserInfo} from '@utils/tool/getUserInfo.js'
import {encrypt} from "@utils/tool";

const formRef = ref(null)

const rules = reactive({
  oldPwd: formRules.oldPwd,
  newPwd: formRules.newPwd,
  newPwdCheck: [...formRules.newPwdCheck,
    {validator: validatePass, trigger: 'blur'}
  ],
});
const fromData = reactive({
  //原密码
  oldPwd: '',
  //新密码
  newPwd: '',
  //确认新密码
  newPwdCheck: '',
})
const openChangePwd = () => {
  fromData.oldPwd = ''
  fromData.newPwd = ''
  fromData.newPwdCheck = ''
  formRef.value.clearValidate()
}

function validatePass(rule, value, callback) {
  if (value === '') {
    callback(new Error('请再次输入密码'));
  } else if (value !== fromData.newPwd) {
    callback(new Error('两次输入密码不一致!'));
  } else {
    callback();
  }
}

const dialogVisible = ref(false)
const {proxy} = getCurrentInstance();
const handleClickUpdate = () => {
  dialogVisible.value = true
}
//修改密码
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      let oldStr = encrypt(fromData.oldPwd)
      let pwd = encrypt(fromData.newPwd)
      //请求修改密码接口
      updatePwd({
        originalPassword: oldStr,
        password: pwd,
      }).then(async (res) => {
        if (res.code !== 0) return proxy.$message.error(res.msg)
        proxy.$message.success('修改成功')
        dialogVisible.value = false
        await setBaseUserInfo()
      })
    } else {
      return false;
    }
  });
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "changPwd",
});
</script>

<style lang="scss" scoped>
.change-pwd-btn {
  color: $mainColor;
  cursor: pointer;
  font-weight: 600;
}

.shop-dialog {
  .btn-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 88px;
      height: 40px;
    }
  }
}
</style>
