<template>
  <el-dialog
      v-model="modelValue"
      title="确认不再使用，并注销账户？"
      width="520"
      modal-class="shop-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openChangePwd"
      :before-close="closeDia"
  >
    <el-form
        :rules="rules"
        ref="formRef"
        :model="fromData"
        class="chang-pwd-form large-input"
        hide-required-asterisk
        label-width="100px"
        label-position="left"
    >
    <el-form-item
          label="企业名称"
      >
        <div class="phone">{{ baseInfo.companyName || '--' }}</div>
      </el-form-item>
      <el-form-item
          label="联系电话"
      >
        <div class="phone">{{ baseInfo.phone || '--' }}</div>
      </el-form-item>
      <el-form-item
          prop="code"
          label="验证码"
      >
        <div class="form-item2">
          <el-input
          v-elInput
              v-model="fromData.code"
              type="number"
              class="no-number large-input"
              placeholder="请输入验证码"
              maxlength="6"
              autocomplete="off"
              input-style="font-size: 14px;"
          />
          <div class="code-btn" :class="isOK?'':'disabled'" @click.stop='handleClickGetCode'>{{ str }}</div>
        </div>
      </el-form-item>
      <el-form-item
          prop="pwd"
          label="输入密码"
      >
        <el-input
        v-elInput
            v-model="fromData.pwd"
            type="password"
            class="no-number large-input"
            placeholder="请输入密码"
            maxlength="30"
            autocomplete="off"
            input-style="font-size: 14px;"
            onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
        />
      </el-form-item>
      <el-form-item
      >
        <div class="err-text">注销后，该企业信息将会清空，且不能恢复，再次使用请重新注册。</div>
      </el-form-item>
      <el-form-item
      >
        <div class="btn-box">
          <button type="button" class="wy-button info mr_16" @click="closeDia">取消</button>
          <button type="button" class="wy-button primary" @click="submitForm(formRef)">确认</button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {codeSend, logCancel} from "@utils/api/person/index.js"
import {formRules} from '../../../config'
import {encrypt} from "@utils/tool";
import {useStore} from "vuex";
import {codeBtn} from "@utils/tool/getCode";
import {useRouter} from "vue-router";
import {removeToken} from "@utils/tool/token";

const {str, getCode, isOK} = codeBtn()

const router = useRouter()
const store = useStore()
const formRef = ref(null)
const baseInfo = computed(() => store.state.baseic)
const rules = reactive({
  code: formRules.code,
  pwd: formRules.pwd,
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
})

const {modelValue} = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const fromData = reactive({
  code: '',
  pwd: '',
})
const openChangePwd = () => {
  fromData.code = ''
  fromData.pwd = ''
  formRef.value.clearValidate()
}

const handleClickGetCode = () => {
  if (!isOK.value) return
  codeSend({phone: fromData.phone, placeId: 6}).then((res) => {
    if (res.code !== 0) return proxy.$message.error(res.msg)
    proxy.$message.success('验证码发送成功')
    getCode()
  })
}

const {proxy} = getCurrentInstance();
const closeDia = () => {
  emit('update:modelValue', false)
}
//修改密码
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      let pwd = encrypt(fromData.pwd)
      //请求修改密码接口
      logCancel({
        vcode: fromData.code,
        password: pwd,
      }).then(async (res) => {
        if (res.code !== 0) return proxy.$message.error(res.msg)
        proxy.$message.success('注销成功')
        setTimeout(() => {
          proxy.$cookies.remove("userInfo");
          removeToken()
          router.push({
            path: "/shop/login",
          });
        }, 500)
      })
    } else {
      return false;
    }
  });
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "changPwd",
});
</script>

<style lang="scss" scoped>
.change-pwd-btn {
  color: $mainColor;
  cursor: pointer;
  font-weight: 600;
}

.shop-dialog {
  .err-text {
    color: red;
    font-size: 12px;
  }

  .form-item2 {
    width: 100%;
    position: relative;

    .tips-text {
      position: absolute;
      right: 0;
      top: -24px;
      font-size: 14px;
      color: #697691;

      .main {
        color: #9CA5B8;
      }
    }

    .code-btn {
      color: #466AFE;
      height: 48px;
      line-height: 48px;
      position: absolute;
      right: 24px;
      font-size: 14px;
      top: 0;
      cursor: pointer;
      user-select: none;

      &.disabled {
        color: #697691;
        cursor: not-allowed;
      }
    }
  }

  .btn-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 88px;
      height: 40px;
    }
  }
}
</style>
