<template>
  <div class="shop-personal-info animate__animated animate__fadeIn container dp_f">
    <user-info></user-info>
    <div class="company-info">
      <div class="item">
        <div class="title">联系电话</div>
        <div class="value">{{ baseInfo.phone || '--' }}</div>
      </div>
      <div class="item">
        <div class="title">登录账号</div>
        <div class="value">{{ baseInfo.account }}</div>
      </div>
      <div class="item">
        <div class="title">邀请码</div>
        <div class="value">{{ baseInfo.mcode }}</div>
      </div>
      <div class="item">
        <div class="title">密码</div>
        <change-pwd class="value"/>
      </div>
      <div class="item" v-if="baseInfo.role === 1">
        <div class="value red" @click="showLogout = true">注销账号</div>
      </div>
    </div>
    <logout v-model="showLogout"/>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import ChangePwd from "@views/shop/personal/personal/components/changPwd.vue";
import UserInfo from './userInfo.vue'
import Logout from "@views/shop/personal/personal/components/logout.vue";
import {useStore} from "vuex";

const showLogout = ref(false)
const store = useStore()
const {proxy} = getCurrentInstance();
const baseInfo = computed(() => store.state.baseic)

</script>
<script>
import {defineComponent} from "vue";
import memberTime from "../../../components/shopUser/memberTime.vue";

export default defineComponent({
  components: { memberTime },
  name: "shopPersonalInfo",
});
</script>
<style lang="scss" scoped>
.shop-personal-info {
  padding-top: 32px;
}
.user-info{
  width: 280px;
  height: 520px;
  box-sizing: border-box;
  text-align: center;
  padding-top: 40px;
  background-color: #F4F6FF;
  border-radius: 16px;
  overflow: hidden;
  margin-right: 30px;
  .shop-user-img{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 auto;
  }
  .user-nickName{
    color: $shopFsColor;
    line-height: 1;
    margin-top: 24px;
    font-weight: bold;
    font-size: 16px;
  }
  .company-name{
    color: $shopFsColor;
    font-size: 14px;
    margin-top: 18px;
  }
  .wy-level {
    width: 80px;
    height: 24px;
    background: linear-gradient(-40deg, #121F35 0%, #283C5C 100%);
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #FFFFFF;
    font-size: 12px;
    margin: 24px auto 0;
  }
  .btn-box{
    margin-top: 70px;
    >button{
      width: 120px;
      height: 36px;
      line-height: 36px;
    }
  }
}
.company-info{
  flex: 1;
  height: 520px;
  border-radius: 12px;
  background-color: #F7F8FA;
  padding: 48px 0 0 80px;
  box-sizing: border-box;
  .item{
    &:not(:first-child){
      margin-top: 50px;
    }
    >div{
      line-height: 1;
    }
    .title{
      font-size: 14px;
      color: $shopFsColor2;
    }
    .value{
      margin-top: 14px;
      font-size: 14px;
      color: $shopFsColor;
      font-weight: bold;
      display: flex;
      align-items: center;

      &.red {
        color: #FF6669;
        cursor: pointer;
      }
    }
    :deep(.change-pwd-btn) {
      margin-top: 14px;
      color: $mainColor;
    }
  }
}
</style>